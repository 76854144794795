<template>
  <div class="survey-block" :class="$parent.$parent.isDesktop ? 'col-8' : 'col-12'">
    <div class="row justify-content-md-center">
      <div class="col-12">
        <p class="p-2 mb-1 font-larger">{{question}}</p>
      </div>
      <div class="col-12 d-flex flex-row p-1">
        <div class="col p-0 d-flex justify-content-center"
          :class="$parent.$parent.isDesktop ? '' : 'p-0'"
          v-for="(itm, index) in ratingArr"
          :key="index"
        >
          <div 
            class="m-1 pointer rating-custom-radio"
            :style="`background: ${itm.ratingColor}`"
            @click="optionSelectHandler(questionId, itm.rating)"
          >
          <input type="radio" class="survey-radio" :value="itm.rating" :name="'survey_' + questionId" >
          <p class="font-weight-bold d-flex justify-content-center center" style="color: #fff;">{{itm.rating}}</p>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex">
        <div class="col p-2" style="opacity: 0.9;"><b>Unlikely</b></div>
        <div class="col p-2 text-right" style="opacity: 0.9;"><b>Extremely likely</b></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      ratingArr: [
        {
          rating: '0',
          ratingColor: '#E04C4C'
        },
        {
          rating: '1',
          ratingColor: '#E05353'
        },
        {
          rating: '2',
          ratingColor: '#FF7E00'
        },
        {
          rating: '3',
          ratingColor: '#FFC600'
        },
        {
          rating: '4',
          ratingColor: '#00FFEA'
        },
        {
          rating: '5',
          ratingColor: '#3ACFB8'
        },
        {
          rating: '6',
          ratingColor: '#14B498'
        },
        {
          rating: '7',
          ratingColor: '#00FFEA'
        },
        {
          rating: '8',
          ratingColor: '#45DD70'
        },
        {
          rating: '9',
          ratingColor: '#55F44E'
        },
        {
          rating: '10',
          ratingColor: '#45DD70'
        }
      ]
    }
  },
  props: ['questionId', 'question'],
  methods: {
    optionSelectHandler(questionId, rating) {
      let elem = document.getElementsByName('survey_' + questionId)
      if(elem && elem.length && elem[rating]) {
        elem[rating].checked = true
        Array.from(elem).forEach((itm) => {
          if (itm.checked === true) {
            itm.parentElement.classList.add('option-select')
          } else {
            itm.parentElement.classList.remove('option-select')
          }
        })

      }
    }
  }
}
</script>
<style scroped>
.survey-block {
  background: #EFEFEF;
  border-radius: 8px;
}
.rating-custom-radio{
  height: 50px;
  width: 40px;
  border-radius: 5px;
}
.center {
  padding-top: 30%;
}
.survey-radio {
  display: none;
}
.option-select {
  border: 3px solid black;
}
@media screen and (max-width: 480px) {
  .rating-custom-radio{
    height: 30px;
    width: 25px;
    border-radius: 5px;
  }
  .center {
    padding-top: 17%;
  }
}
@media screen and (max-width: 360px) {
  .rating-custom-radio{
    height: 25px;
    width: 20px;
    border-radius: 5px;
  }
  .center {
    padding-top: 7%;
  }
}
</style> 